<template>
  <div class="chart-container">
    <p>Программа лояльности</p>
    <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
  </div>
</template>

<style scoped>
.chart-container {
  max-width: 600px; /* Задаем максимальную ширину контейнера */
  max-height: 400px; /* Задаем максимальную высоту контейнера */
  margin: 0 auto; /* Центрируем контейнер по горизонтали */
  padding: 20px;
}
@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: {
        labels: [
          "1.09",
          "2.09",
          "3.09",
          "4.09",
          "5.09",
          "6.09",
          "7.09",
          "1.09",
          "2.09",
          "3.09",
          "4.09",
          "5.09",
          "6.09",
          "7.09"
        ],
        datasets: [
        {
            label: 'В зале',
            backgroundColor: '#13414E',
            data: [25000,28000,15000,55000,37000,40000,20000]
          },
          {
            label: 'Доставка',
            backgroundColor: '#006D5A',
            data: [65000,18000,45000,25000,37000,60000,57000]
          },
          {
            label: 'Навынос',
            backgroundColor: '#14BF91',
            data: [15000,48000,35000,75000,47000,20000,17000]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        
        scales: {
          x: {
            stacked: true,
            categoryPercentage: 0.3, // Процент от ширины доступной категории (0.0 до 1.0)
            barThickness: 100
          },
          y: {
            stacked: true
          }
        }
      }
    }
  }
}
</script>
